import ConfigProvider from 'context/ConfigProvider';
import UserContextProvider from 'context/UserContextProvider';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { defaultTheme } from 'themes/default';

import useIsApp from 'hooks/useIsApp';
import initVariables from 'util/initVariables';
import useLogPage from 'util/useLogPage';
import { SafeArea } from '@capacitor-community/safe-area';
import './index.css';

const WebRouting = React.lazy(async () => import('./pages/web/Routing'));
const AppRouting = React.lazy(async () => import('./pages/app/Routing'));

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

const Switch = () => {
    const isApp = useIsApp();
    useLogPage();

    // Ensure that the safe are is being enabled
    SafeArea.enable({ config: {} }).then();

    if (isApp) return <AppRouting />;
    return <WebRouting />;
};

// As courtesy to all the layout the css variables are init already here
initVariables();

root.render(
    <React.StrictMode>
        <ThemeProvider theme={defaultTheme}>
            <ConfigProvider>
                <UserContextProvider>
                    <BrowserRouter>
                        <Switch />
                    </BrowserRouter>
                </UserContextProvider>
            </ConfigProvider>
        </ThemeProvider>
    </React.StrictMode>
);
