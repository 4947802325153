import { type DefaultTheme } from 'styled-components';

export const defaultTheme: DefaultTheme = {
    colors: {
        default: '#3d3d3d',
        defaultInverse: '#ffffff',
        defaultSupport: '#ededed',

        primary: '#0c497a',
        primaryInverse: '#ffffff',

        secondary: '#3fab49',
        secondaryInverse: '#ffffff',

        tertiary: '#a8a8a8',
        tertiaryInverse: '#ffffff',

        error: '#cc0000',
        errorInverse: '#ffffff',

        background: '#ffffff',
        disabled: '#f4f4f4',
        lightGray: '#ededed',
        badge: '#cc0000',
    },
    screens: {
        large: 1512,
        medium: 1200,
        small: 800,
        mobile: 480
    },
    maxWidth: 1050,
    navSize: {
        small: 60,
        other: 60,
        labelCollapse: 348
    }
};
